<template>
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.474 37.6499C67.1482 34.3162 65.5909 31.2238 63.1067 28.977C60.6224 26.7302 57.3896 25.4904 54.04 25.4999H44.26C40.9105 25.4904 37.6777 26.7302 35.1934 28.977C32.7091 31.2238 31.1519 34.3162 30.826 37.6499L28.9075 56.8499C28.8866 57.0585 28.9096 57.2692 28.975 57.4683C29.0405 57.6675 29.147 57.8507 29.2876 58.0062C29.4282 58.1616 29.5999 58.2859 29.7915 58.371C29.9831 58.456 30.1904 58.4999 30.4 58.4999H67.9C68.1103 58.501 68.3184 58.4578 68.511 58.3732C68.7035 58.2887 68.876 58.1645 69.0175 58.009C69.1589 57.8534 69.2661 57.6698 69.332 57.4701C69.3979 57.2704 69.4211 57.0592 69.4 56.8499L67.474 37.6499Z"
      fill="#4ADE80"
      style="fill: #4ade80; fill: color(display-p3 0.2902 0.8706 0.502); fill-opacity: 1" />
    <path
      d="M49.15 22.5C54.5348 22.5 58.9 18.1348 58.9 12.75C58.9 7.36522 54.5348 3 49.15 3C43.7652 3 39.4 7.36522 39.4 12.75C39.4 18.1348 43.7652 22.5 49.15 22.5Z"
      fill="#4ADE80"
      style="fill: #4ade80; fill: color(display-p3 0.2902 0.8706 0.502); fill-opacity: 1" />
    <path
      d="M41.974 37.6499C41.6482 34.3162 40.0909 31.2238 37.6067 28.977C35.1224 26.7302 31.8896 25.4904 28.54 25.4999H18.76C15.4105 25.4904 12.1777 26.7302 9.69341 28.977C7.20914 31.2238 5.65192 34.3162 5.32604 37.6499L3.40754 56.8499C3.38658 57.0585 3.40957 57.2692 3.47503 57.4683C3.54049 57.6675 3.64697 57.8507 3.7876 58.0062C3.92823 58.1616 4.09988 58.2859 4.29149 58.371C4.4831 58.456 4.69041 58.4999 4.90004 58.4999H42.4C42.6103 58.501 42.8185 58.4578 43.011 58.3732C43.2035 58.2887 43.376 58.1645 43.5175 58.009C43.6589 57.8534 43.7661 57.6698 43.832 57.4701C43.8979 57.2704 43.9211 57.0592 43.9 56.8499L41.974 37.6499Z"
      fill="#22C55E"
      style="fill: #22c55e; fill: color(display-p3 0.1333 0.7725 0.3686); fill-opacity: 1" />
    <path
      d="M36.4 52.5C41.3706 52.5 45.4 48.4706 45.4 43.5C45.4 38.5294 41.3706 34.5 36.4 34.5C31.4295 34.5 27.4 38.5294 27.4 43.5C27.4 48.4706 31.4295 52.5 36.4 52.5Z"
      fill="#86EFAC"
      style="fill: #86efac; fill: color(display-p3 0.5255 0.9373 0.6745); fill-opacity: 1" />
    <path
      d="M59.6426 44.5065C59.3809 44.212 59.0637 43.9719 58.7091 43.8C58.3545 43.6281 57.9696 43.5277 57.5762 43.5045C57.1828 43.4814 56.7888 43.536 56.4165 43.6652C56.0443 43.7944 55.7011 43.9957 55.4066 44.2575L42.7586 55.5H30.0416L17.3936 44.25C17.0991 43.9882 16.756 43.787 16.3837 43.6579C16.0115 43.5287 15.6174 43.4741 15.2241 43.4972C14.8307 43.5204 14.4458 43.6207 14.0912 43.7926C13.7367 43.9645 13.4194 44.2046 13.1576 44.499C12.8958 44.7935 12.6946 45.1367 12.5655 45.509C12.4363 45.8812 12.3817 46.2753 12.4048 46.6686C12.428 47.062 12.5283 47.4469 12.7002 47.8015C12.8721 48.156 13.1121 48.4733 13.4066 48.735L25.9001 59.85V67.5C25.9001 67.8979 26.0582 68.2794 26.3395 68.5607C26.6208 68.842 27.0023 69 27.4001 69H45.4001C45.798 69 46.1795 68.842 46.4608 68.5607C46.7421 68.2794 46.9001 67.8979 46.9001 67.5V59.85L59.3936 48.75C59.6893 48.4882 59.9303 48.1705 60.1029 47.8153C60.2755 47.4601 60.3762 47.0743 60.3994 46.68C60.4225 46.2857 60.3676 45.8908 60.2377 45.5178C60.1079 45.1448 59.9056 44.8012 59.6426 44.5065Z"
      fill="#86EFAC"
      style="fill: #86efac; fill: color(display-p3 0.5255 0.9373 0.6745); fill-opacity: 1" />
    <path
      d="M23.65 22.5C29.0348 22.5 33.4 18.1348 33.4 12.75C33.4 7.36522 29.0348 3 23.65 3C18.2652 3 13.9 7.36522 13.9 12.75C13.9 18.1348 18.2652 22.5 23.65 22.5Z"
      fill="#22C55E"
      style="fill: #22c55e; fill: color(display-p3 0.1333 0.7725 0.3686); fill-opacity: 1" />
  </svg>
</template>
